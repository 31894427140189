<template>
  <el-card style="box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);">
    <el-tree
      :data="data"
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :props="defaultProps"
    >
      <span
        class="custom-tree-node"
        slot-scope="{ node, data }"
      >
        <span>{{ node.label }}</span>
        <span>
          <el-button
            type="text"
            size="mini"
            v-if="defaultData.id !== data.id"
            @click.stop="selectClassify(data)"
          >
            选择链接
          </el-button>
          <el-button
            v-else
            type="text"
            size="mini"
            disabled
          >
            已选择
          </el-button>
        </span>
      </span>
    </el-tree>
  </el-card>
</template>

<script>
// import { classifyNew } from "@/api/home/banner";
export default {
  name: "classify",
  props: {
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: [
        {
          id: 1,
          name: "景区列表",
        },
        {
          id: 2,
          name: "车辆列表",
        },
        {
          id: 3,
          name: "积分商城",
        },
        {
          id: 4,
          name: "个人中心",
        },
        {
          id: 5,
          name: "我的资料",
        },
        {
          id: 6,
          name: "会员等级",
        },
        {
          id: 7,
          name: "我的押金",
        },
        {
          id: 8,
          name: "我的优惠券",
        },
        {
          id: 9,
          name: "我的订单",
        },
        {
          id: 10,
          name: "我的积分",
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  mounted() {
    // this.getClassify();
  },
  methods: {
    // async getClassify(){
    //   const { data } = await classifyNew()
    //   // // console.log(data)
    //   this.data = data;
    // },
    selectClassify(data) {
      this.$emit("change", data);
    },
  },
};
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>